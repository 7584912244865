export const navigationRoutes = [
  {
    title: 'Profile',
    path: '/profile',
  },
  {
    title: 'Projects',
    path: '/projects',
  },
  {
    title: 'About',
    path: '/about',
  },
];
