export const TAGS = [
  {
    value: 'video',
    label: 'Video',
  },
  {
    value: 'nft',
    label: 'NFT',
  },
  {
    value: 'post',
    label: 'Post',
  },
  {
    value: 'reels',
    label: 'Reels',
  },
];
