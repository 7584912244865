export const CATEGORIES = [
  {
    value: 'discord',
    label: 'Discord',
  },
  {
    value: 'medium',
    label: 'Medium',
  },
  {
    value: 'instagram',
    label: 'Instagram',
  },
  {
    value: 'tiktok',
    label: 'Tiktok',
  },
  {
    value: 'youtube',
    label: 'Youtube',
  },
  {
    value: 'twitter',
    label: 'Twitter',
  },
  {
    value: 'telegram',
    label: 'Telegram',
  },
  {
    value: 'facebook',
    label: 'Facebook',
  },
];
