export const TX_TYPE_OPTIONS = [
  {
    label: 'Deposit',
    value: 'DEPOSIT',
  },
  {
    label: 'System',
    value: 'SYSTEM',
  },
  {
    label: 'Withdrawal',
    value: 'WITHDRAWAL',
  },
  {
    label: 'Payment',
    value: 'PAYMENT',
  },
];
